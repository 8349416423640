import axiosInstance from "./axiosInstance";

export const getPremisesDetails = async (licenseId, premisesId) => {
  const response = await axiosInstance.get(
    `/api/licenses/${licenseId}/premises/${premisesId}`
  );

  return response.data;
};

export const getPremisesSearchResults = async ({
  licenseId,
  page,
  queryString,
}) => {
  const response = await axiosInstance.get(
    `/api/licenses/${licenseId}/premises?page=${page}&${queryString}`
  );

  return response.data;
};

export const getLicense = async (licenseId) => {
  const response = await axiosInstance.get(`/api/licenses/${licenseId}`);

  return response.data;
};
