import React from "react";
import FilterList from "../Filter/FilterList";

const SearchControls = ({
  filtersApplied,
  appliedFilters,
  removeFilterHandler,
  clearFiltersHandler,
  filteredSearchPerformed,
}) => {
  return (
    <div className="ds_search-controls">
      <div className="ds_skip-links ds_skip-links--static">
        <ul className="ds_skip-links__list">
          <li className="ds_skip-links__item">
            <a
              className="ds_skip-links__link"
              href="#search-results"
            >
              Skip to results
            </a>
          </li>
        </ul>
      </div>
      {filtersApplied && (
        <>
          <p className="visually-hidden">There are 6 search filters applied</p>
          <FilterList
            appliedFilters={appliedFilters}
            removeFilterHandler={removeFilterHandler}
          />
        </>
      )}
      {filteredSearchPerformed && (
        <div className="ds_facets">
          <button
            className="ds_facets__clear-button ds_button ds_button--secondary"
            onClick={() => clearFiltersHandler()}
          >
            Clear all filters
            <svg
              className="ds_facet__button-icon"
              aria-hidden="true"
              role="img"
              focusable="false"
            >
              <svg
                viewBox="0 -960 960 960"
                id="cancel"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
              </svg>
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchControls;
