import Axios from "axios";

const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "CF-Access-Client-Id": process.env.REACT_APP_CF_ACCESS_CLIENT_ID || "",
    "CF-Access-Client-Secret":
      process.env.REACT_APP_CF_ACCESS_CLIENT_SECRET || "",
  },
});

export default axiosInstance;
