import React from "react";

const PremisesList = ({ children }) => {
  return (
    <div className="ds_layout__content">
      <ol className="ds_summary-list">{children}</ol>
    </div>
  );
};

export default PremisesList;
