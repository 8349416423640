import React from 'react';
import Paginator from '../Paginator/Paginator';
import SearchResultsTitle from './SearchResultsTitle';
import SearchControls from './SearchControls';
import SearchResultItem from './SearchResultItem';

const SearchResults = ({
  searchResults,
  currentPage,
  totalPages,
  totalRecords,
  pageRangeFrom,
  pageRangeTo,
  currentSearchTerm,
  filtersApplied,
  filteredSearchPerformed,
  appliedFilters,
  pageClickHandler,
  removeFilterHandler,
  premisesSelectedHandler,
  clearFiltersHandler,
}) => {
  return (
    <div className="ds_search-results">
      <SearchResultsTitle
        currentPage={currentPage}
        totalRecords={totalRecords}
        pageRangeFrom={pageRangeFrom}
        pageRangeTo={pageRangeTo}
        currentSearchTerm={currentSearchTerm}
      />
      <SearchControls
        filtersApplied={filtersApplied}
        appliedFilters={appliedFilters}
        removeFilterHandler={removeFilterHandler}
        clearFiltersHandler={clearFiltersHandler}
        filteredSearchPerformed={filteredSearchPerformed}
      />
      <ul className="ds_search-results__list">
        {searchResults.map((item) => (
          <SearchResultItem
            key={item.id.value}
            item={item}
            premisesSelectedHandler={premisesSelectedHandler}
          />
        ))}
      </ul>
      <Paginator
        currentPage={currentPage}
        totalPages={totalPages}
        pageClickHandler={pageClickHandler}
      />
    </div>
  );
};

export default SearchResults;
