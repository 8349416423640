import React from "react";

const SearchForm = ({ searchTerm, setSearchTerm, searchHandler }) => {
  return (
    <div className="ds_site-search">
      <form
        role="search"
        className="ds_site-search__form"
      >
        <label
          className="ds_label visually-hidden"
          htmlFor="site-search"
        >
          Search
        </label>
        <div className="ds_input__wrapper ds_input__wrapper--has-icon">
          <input
            name="q"
            required=""
            id="site-search"
            className="ds_input ds_site-search__input"
            type="search"
            placeholder="Search"
            autoComplete="off"
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
          />
          <button
            type="submit"
            className="ds_button js-site-search-button"
            onClick={(event) => searchHandler(event)}
          >
            <span className="visually-hidden">Search</span>
            <svg
              className="ds_icon"
              aria-hidden="true"
              role="img"
            >
              <svg
                viewBox="0 0 24 24"
                id="search"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0h24v24H0z"
                  fill="none"
                />
                <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
              </svg>
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchForm;
