import React from "react";

const PremisesHeader = ({ onClick, selectedPremises }) => {
  return (
    <div className="ds_layout__header">
      <nav aria-label="Breadcrumb">
        <ol className="ds_breadcrumbs">
          <li className="ds_breadcrumbs__item">
            <a
              className="ds_breadcrumbs__link"
              href="/"
              onClick={(event) => onClick(event)}
            >
              Back to search
            </a>
          </li>
        </ol>
      </nav>
      <header className="ds_page-header">
        <h1 className="ds_page-header__title">
          {selectedPremises.premisesName.value}
        </h1>
      </header>
    </div>
  );
};

export default PremisesHeader;
