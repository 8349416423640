import React, { createContext, useEffect, useState } from 'react';
import { getLicense } from '../api';

export const AppContext = createContext();

const AppProvider = ({ children, licenseId }) => {
  const [filterCategories, setFilterCategories] = useState([]);
  const [context, setContext] = useState(null);

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const data = await getLicense(licenseId);

        setContext(data['license']);
        setFilterCategories(data['filter_cateogories']);
      } catch (error) {
        console.error('Error fetching types:', error);
      }
    };

    fetchTypes();
  }, [licenseId]);

  if (!context?.id) return null;

  return (
    <AppContext.Provider
      value={{
        context,
        filterCategories,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
