import React from "react";
import PropTypes from "prop-types";

const FilterItem = ({ id, title, filters, removeFilterHandler, items }) => {
  return (
    <div className="ds_facet-group">
      <dt className="ds_facet__group-title">{title}: </dt>
      <dd className="ds_facet-wrapper">
        {filters.map((filter, index) => {
          const item = items.find((item) => item.value === filter);
          return (
            <div
              key={index}
              className="ds_facet"
            >
              {item ? item.label : filter}
              <button
                type="button"
                aria-label={`Remove '${title}' filter`}
                className="ds_facet__button"
                onClick={(event) => removeFilterHandler(title, filter)}
              >
                <svg
                  className="ds_facet__button-icon"
                  aria-hidden="true"
                  role="img"
                  focusable="false"
                >
                  <svg
                    viewBox="0 -960 960 960"
                    id="cancel"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
                  </svg>
                </svg>
              </button>
            </div>
          );
        })}
      </dd>
    </div>
  );
};

FilterItem.propTypes = {
  title: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired,
  filterName: PropTypes.string.isRequired,
  removeFilterHandler: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
};

export default FilterItem;
