import React from "react";

const Header = ({ title, subtitle, description }) => {
  const headerSubtitleText = `Use the search tool to view all premises listed on the ${subtitle}`;

  return (
    <div className="ds_layout__header">
      <header className="ds_page-header">
        <h1 className="ds_page-header__title page_title">{title}</h1>
        <div className="ds_leader">{headerSubtitleText}</div>
        <div>{description}</div>
      </header>
    </div>
  );
};

export default Header;
