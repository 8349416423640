import React, { useContext, useMemo } from 'react';
import Accordion, { AccordionItem } from '../Accordion/Accordion';
import { CheckboxContainer } from '../Checkbox/Checkbox';
import { AppContext } from '../../contexts/AppProvider';

const FilterSection = React.memo(
  ({ title, items, itemFilter, filterSelectedHandler }) => (
    <fieldset>
      <legend className="visually-hidden">
        Select which {title?.toLowerCase()} you would like to see
      </legend>
      <div className="ds_search-filters__scrollable">
        <div className="ds_search-filters__checkboxes">
          <CheckboxContainer
            items={items}
            itemFilter={itemFilter}
            filterName={title}
            onFilterChange={filterSelectedHandler}
          />
        </div>
      </div>
    </fieldset>
  )
);

const SearchFilters = ({
  searchFilters,
  filterSelectedHandler,
  applyFilterHandler,
  clearFiltersHandler,
  filteredSearchPerformed,
}) => {
  const { filterCategories } = useContext(AppContext);

  const filterSections = useMemo(() => {
    return filterCategories.map((category) => (
      <AccordionItem
        id={category.id?.toString()}
        key={category.id}
        title={category.name}
      >
        <FilterSection
          title={category.name}
          items={category.items}
          itemFilter={searchFilters[category.name]}
          filterName={category.id}
          filterSelectedHandler={filterSelectedHandler}
        />
      </AccordionItem>
    ));
  }, [filterCategories, searchFilters, filterSelectedHandler]);

  return (
    <div className="ds_search-filters">
      <div className="ds_details ds_no-margin" data-module="ds-details">
        <input
          id="filters-toggle"
          type="checkbox"
          className="ds_details__toggle visually-hidden"
        />
        <label htmlFor="filters-toggle" className="ds_details__summary">
          Search filters
        </label>
        <div className="ds_skip-links ds_skip-links--static">
          <ul className="ds_skip-links__list">
            <li className="ds_skip-links__item">
              <a className="ds_skip-links__link" href="#search-results">
                Skip to results
              </a>
            </li>
          </ul>
        </div>
        <div className="ds_details__text">
          <form id="filters">
            <h3 className="ds_search-filters__title ds_h4">Filter by</h3>
            <Accordion small="true">{filterSections}</Accordion>
            <button
              type="submit"
              className="ds_button ds_button--primary ds_button--max ds_!_margin-bottom--1"
              onClick={(event) => applyFilterHandler(event)}
            >
              Apply filters
            </button>
            {filteredSearchPerformed && (
              <button
                type="button"
                className="ds_button ds_button--secondary ds_button--max"
                onClick={clearFiltersHandler}
              >
                Clear all filters
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SearchFilters);
