import './App.css';
import '@scottish-government/design-system/dist/css/design-system.css';
import React from 'react';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import AppProvider from './contexts/AppProvider';
import Premises from './pages/Premises';
import PublicRegister from './pages/PublicRegister';

function App({ licenseId }) {
  if (!licenseId) return null;

  return (
    <div className="App">
      <AppProvider licenseId={licenseId}>
        <HashRouter>
          <Routes>
            <Route index element={<PublicRegister />} />
            <Route path="premises/:id" element={<Premises />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </HashRouter>
      </AppProvider>
    </div>
  );
}

export default App;
