import React from 'react';
import PropTypes from 'prop-types';

const statusColors = { 'Active': 'green', 'Inactive': 'grey', 'Suspended': 'red' };

const PremisesStatusItem = ({ name, value }) => {
  if (!value) return null;

  return (
    <li className="ds_summary-list__item">
      <span className="ds_summary-list__key" id="q2">
        {name}
      </span>
      <span className="ds_summary-list__value">
        <q className="ds_summary-list__answer">
          <div className={`ds_tag ds_tag--${statusColors[value]}`}>{value}</div>
        </q>
      </span>
    </li>
  );
};

PremisesStatusItem.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
};

export default PremisesStatusItem;
