import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useContext, useState } from 'react';
import { AppContext } from '../contexts/AppProvider';
import { getPremisesDetails } from '../api';
import PremisesItem from '../components/Premises/PremisesItem';
import PremisesList from '../components/Premises/PremisesList';
import PremisesHeader from '../components/Premises/PremisesHeader';
import PremisesStatusItem from '../components/Premises/PremisesStatusItem';

const hiddenListKeys = ['id', 'status'];

const Premises = () => {
  const { context } = useContext(AppContext);
  const [selectedPremises, setSelectedPremises] = useState({
    premisesName: '',
    status: '',
    type: '',
    localAuthority: [''],
    address: '',
    productsSold: [''],
    businessAtPremises: '',
    floorArea: '',
  });

  const { id } = useParams();
  let navigate = useNavigate();

  const backToSearchHandler = (e) => {
    e.preventDefault();

    // Retrieve stored filters
    const storedFilters = localStorage.getItem('searchFilters');

    let restoredFilters = storedFilters ? JSON.parse(storedFilters) : {};

    const newSearchParams = new URLSearchParams();

    Object.entries(restoredFilters).forEach(([key, value]) => {
      if (value.length > 0) {
        newSearchParams.append(key, value.toString());
      }
    });

    navigate(`/?${newSearchParams.toString()}`, { replace: true });
  };

  useEffect(() => {
    const initPremises = async () => {
      try {
        const response = await getPremisesDetails(context.id, id);
        if (response.data.length) {
          setSelectedPremises(response.data[0]);
        } else {
          navigate('/');
        }
      } catch (error) {
        navigate('/');
      }
    };

    initPremises();
  }, [id, navigate]);

  return (
    <>
      <main className="ds_layout  ds_layout--search-results--filters">
        {selectedPremises !== null && (
          <>
            <PremisesHeader
              onClick={backToSearchHandler}
              selectedPremises={selectedPremises}
            />
            <PremisesList>
              <PremisesStatusItem
                name={selectedPremises.status.label}
                value={selectedPremises.status.value?.toString()}
              />
              {Object.entries(selectedPremises)
                .filter(([key]) => !hiddenListKeys.includes(key))
                .map(([key, obj]) => (
                  <PremisesItem
                    key={key}
                    name={obj.label}
                    value={obj.value?.toString()}
                  />
                ))}
            </PremisesList>
          </>
        )}
      </main>
    </>
  );
};

export default Premises;
