import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Read the context from the HTML element's data attribute
const rootElement = document.getElementById('epass-widget');
const registerId = rootElement.getAttribute('data-register-id');

if (!registerId) {
  throw new Error(
    'Error: Missing "data-register-id" attribute in #epass-widget'
  );
}

const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <App licenseId={registerId} />
  </React.StrictMode>
);

reportWebVitals();
