import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getPremisesSearchResults } from "../api";
import { AppContext } from "../contexts/AppProvider";
import SearchForm from "../components/Search/SearchForm";
import SearchFilters from "../components/Search/SearchFilters";
import SearchResults from "../components/Search/SearchResults";
import Header from "../components/Header";

const migratedString =
  "Premises with an 'Updates Required' tag are fully registered and compliant but need the applicant to update their details to keep the record accurate.";

const PublicRegister = () => {
  const { context, filterCategories } = useContext(AppContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [migratedText, setMigratedText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState(searchParams.get("query") ?? "");
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState("1");
  const [pageRangeFrom, setPageRangeFrom] = useState(0);
  const [pageRangeTo, setPageRangeTo] = useState(0);
  const [filteredSearchPerformed, setFilteredSearchPerformed] = useState(
    !!searchParams.get("query")
  );
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [reloadRegister, setReloadRegister] = useState(false);
  const storageFilterName = useMemo(
    () => `searchFilter${context.id}`,
    [context]
  );

  const initialFilters = useMemo(() => {
    const filters = {};
    filterCategories.forEach((category) => {
      filters[category.name] = [];
    });
    return filters;
  }, [filterCategories]);

  const [searchFilters, setSearchFilters] = useState(initialFilters);

  const getResults = useCallback(
    async (page = 1, term = searchTerm) => {
      let queryParams = [];

      if (term) {
        queryParams.push(`query=${term}`);
      }

      Object.entries(searchFilters).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          const formattedKey = key.replace(/\s+/g, "");
          queryParams.push(`${formattedKey}=${value.join(",")}`);
          setFiltersApplied(true);
        }
      });

      let queryString = queryParams.join("&");

      try {
        const response = await getPremisesSearchResults({
          licenseId: context.id,
          page,
          queryString,
        });

        if (response.status === 422) {
          return getResults(1);
        }

        localStorage.setItem(storageFilterName, JSON.stringify(searchFilters));

        if (migratedText === "") {
          checkIfMigratedResults(response.data);
        }

        setSearchResults(response.data);
        setTotalRecords(response.meta.total);
        setCurrentPage(response.meta.current_page);
        setTotalPages(response.meta.last_page);
        setPageRangeFrom(response.meta.from);
        setPageRangeTo(response.meta.to);
        setFilteredSearchPerformed(queryParams.length > 0);
        setCurrentSearchTerm(searchTerm);
        setSearchParams(new URLSearchParams(queryString));
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    },
    [searchTerm, searchFilters, storageFilterName]
  );

  const checkIfMigratedResults = (results) => {
    const hasMigrated = results.some(
      (item) => item.metadata.applicationStatus === "migrated"
    );

    if (hasMigrated) setMigratedText(migratedString);
  };

  const searchHandler = useCallback(
    (e) => {
      e.preventDefault();
      setCurrentSearchTerm(searchTerm);
      getResults(1);
    },
    [searchTerm, getResults]
  );

  const clearFiltersHandler = useCallback(() => {
    setSearchTerm("");
    setCurrentSearchTerm("");
    setFiltersApplied(false);
    setFilteredSearchPerformed(false);
    setReloadRegister(true);
    setSearchFilters(initialFilters);
    localStorage.removeItem(storageFilterName);
  }, [storageFilterName]);

  const pageClickHandler = useCallback(
    (page) => {
      getResults(page);
      window.scrollTo({ top: 0, behavior: "instant" });
    },
    [getResults]
  );

  const premisesSelectedHandler = useCallback(
    (e, premisesId) => {
      e.preventDefault();
      navigate(`/premises/${premisesId}`);
    },
    [navigate]
  );

  useEffect(() => {
    if (reloadRegister) {
      getResults(1);
      setReloadRegister(false);
    }
  }, [reloadRegister, getResults]);

  const removeFilterHandler = useCallback(
    (name, value) => {
      setSearchFilters((prevFilters) => {
        const filterArray = prevFilters[name].filter((item) => item !== value);
        return { ...prevFilters, [name]: filterArray };
      });
      setReloadRegister(true);
      getResults(1);
    },
    [getResults]
  );

  const filterSelectedHandler = useCallback((target, label) => {
    const { checked, value } = target;

    setSearchFilters((prevFilters) => {
      const newFilterArray = prevFilters[label] ? [...prevFilters[label]] : [];

      if (checked) {
        if (!newFilterArray.includes(value)) {
          newFilterArray.push(value);
        }
      } else {
        const index = newFilterArray.indexOf(value);
        if (index > -1) {
          newFilterArray.splice(index, 1);
        }
      }

      return { ...prevFilters, [label]: newFilterArray };
    });
  }, []);

  const applyFilterHandler = useCallback(
    (event) => {
      event.preventDefault();
      localStorage.setItem(storageFilterName, JSON.stringify(searchFilters));
      searchHandler(event);
    },
    [storageFilterName, searchFilters, searchHandler]
  );

  useEffect(() => {
    const storedFilters =
      JSON.parse(localStorage.getItem(storageFilterName)) || {};

    setSearchFilters(storedFilters);

    getResults(searchParams.get("page") || 1);
  }, []);

  return (
    <main
      className="ds_layout ds_layout--search-results--filters"
      style={{ minHeight: "600px" }}
    >
      <Header
        title="Search"
        subtitle={context.name}
        description={migratedText}
      />
      <div className="ds_layout__content">
        <SearchForm
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          searchHandler={searchHandler}
        />
      </div>
      <div className="ds_layout__sidebar">
        <SearchFilters
          searchFilters={searchFilters}
          filterSelectedHandler={filterSelectedHandler}
          applyFilterHandler={applyFilterHandler}
          clearFiltersHandler={clearFiltersHandler}
          filteredSearchPerformed={filteredSearchPerformed}
        />
      </div>
      <div className="ds_layout__list">
        <SearchResults
          searchResults={searchResults}
          currentPage={currentPage}
          totalPages={totalPages}
          totalRecords={totalRecords}
          pageRangeFrom={pageRangeFrom}
          pageRangeTo={pageRangeTo}
          currentSearchTerm={currentSearchTerm}
          filtersApplied={filtersApplied}
          filteredSearchPerformed={filteredSearchPerformed}
          appliedFilters={searchFilters}
          pageClickHandler={pageClickHandler}
          removeFilterHandler={removeFilterHandler}
          premisesSelectedHandler={premisesSelectedHandler}
          clearFiltersHandler={clearFiltersHandler}
        />
      </div>
    </main>
  );
};

export default PublicRegister;
