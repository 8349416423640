import React from "react";

const SearchResultsTitle = ({
  currentPage,
  totalRecords,
  pageRangeFrom,
  pageRangeTo,
  currentSearchTerm,
}) => {
  return (
    <h2
      aria-live="polite"
      className="ds_search-results__title"
    >
      {currentPage > 1 ? (
        <span>
          Showing {pageRangeFrom} to {pageRangeTo} of {totalRecords} results{" "}
        </span>
      ) : (
        <span>
          {totalRecords} result
          {totalRecords > 1 || totalRecords === 0 ? "s " : " "}
        </span>
      )}
      {currentSearchTerm && (
        <>
          for{" "}
          <span className="ds_search-results__title-query">
            {currentSearchTerm}
          </span>
        </>
      )}
    </h2>
  );
};

export default SearchResultsTitle;
