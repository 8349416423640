import React, {useContext} from 'react';
import {AppContext} from "../../contexts/AppProvider";

const SearchResultItem = ({ item, premisesSelectedHandler }) => {
    const { context } = useContext(AppContext);
    const showPremisesType = context?.showPremisesType;

  return (
    <li className="ds_search-result">
      <div>
        <h3 className="ds_search-result__title premises-name">
          <a
            className="ds_search-result__link"
            href="/"
            onClick={(event) => premisesSelectedHandler(event, item.id.value)}
          >
            {item.premisesName.value}
          </a>
        </h3>
        {item.metadata.applicationStatus === 'migrated' && (
          <div className="ds_tag ds_tag--red premises-status-tag">
            Updates Required
          </div>
        )}
      </div>
      <p className="ds_search-result__summary">{item.address.value}</p>
      <dl className="ds_search-result__metadata  ds_metadata  ds_metadata--inline">
        <div className="ds_metadata__item">
          <dt className="ds_metadata__key">Business Type</dt>
          <dd className="ds_metadata__value">
            {item.businessAtPremises.value}
          </dd>
        </div>
          {showPremisesType && (
              <div className="ds_metadata__item">
                  <dt className="ds_metadata__key">Premises Type</dt>
                  <dd className="ds_metadata__value">{item.type.value}</dd>
              </div>
          )}
      </dl>
    </li>
  );
};

export default SearchResultItem;
