import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import DSCheckboxes from "@scottish-government/design-system/src/forms/checkbox/checkboxes";
import HintText from "../Common/HintText";

/**
 * @param {Object} props - Properties for the element
 * @returns {JSX.Element} - The element
 */
export const CheckboxGroup = function ({ children, ...props }) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      new DSCheckboxes(ref.current).init();
    }
  }, [ref]);

  return (
    <div
      className="ds_checkboxes ds_field-group"
      data-module="ds-checkboxes"
      ref={ref}
      {...props}
    >
      {children}
    </div>
  );
};
CheckboxGroup.propTypes = {
  children: PropTypes.element,
};

export const CheckboxContainer = ({
  items = [],
  itemFilter = [],
  filterName,
  onFilterChange,
}) => {
  return (
    <>
      {items.map((item) => (
        <div key={item.value}>
          <Checkbox
            id={item.value}
            checked={itemFilter?.includes(item.value) || false}
            label={item.label}
            value={item.value}
            onChange={(event) =>
              onFilterChange(event.currentTarget, filterName)
            }
            small
          />
        </div>
      ))}
    </>
  );
};

/**
 * @param checked
 * @param hintText
 * @param id
 * @param exclusive
 * @param label
 * @param name
 * @param onBlur
 * @param onChange
 * @param small
 * @param {Object} props - Properties for the element
 * @returns {JSX.Element} - The element
 */
const Checkbox = ({
  checked = false,
  hintText,
  id,
  exclusive,
  label,
  name,
  onBlur,
  onChange,
  value,
  small,
}) => {
  const hintTextId = `hint-text-${id}`;
  const behaviour = exclusive ? "exclusive" : "";

  return (
    <>
      {exclusive && <p className="ds_checkbox-separator">or</p>}
      <div className={`ds_checkbox ${small ? "ds_checkbox--small" : ""}`}>
        <input
          className="ds_checkbox__input"
          data-behaviour={behaviour}
          checked={checked}
          id={id}
          name={name || id}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          type="checkbox"
        />
        <label
          className="ds_checkbox__label"
          htmlFor={id}
          aria-describedby={hintTextId}
        >
          {label}
        </label>
        {hintText && (
          <HintText
            id={hintTextId}
            text={hintText}
          />
        )}
      </div>
    </>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  hintText: PropTypes.string,
  id: PropTypes.string.isRequired,
  exclusive: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  small: PropTypes.bool,
};

export default Checkbox;
