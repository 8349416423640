import React, { useContext, useMemo } from "react";
import FilterItem from "./FilterItem";
import { AppContext } from "../../contexts/AppProvider";

const FilterList = ({ appliedFilters, removeFilterHandler }) => {
  const { filterCategories } = useContext(AppContext);
  const filterConfig = useMemo(() => {
    return filterCategories.map((item) => ({
      id: item.id,
      name: item.name,
      items: item.items,
      filters: appliedFilters[item.name],
    }));
  }, [filterCategories, appliedFilters]);

  return (
    <dl className="ds_facets__list">
      {filterConfig.map(
        ({ id, name, items, filters }) =>
          filters?.length > 0 && (
            <FilterItem
              key={id}
              id={id}
              title={name}
              filters={filters}
              removeFilterHandler={removeFilterHandler}
              items={items}
            />
          )
      )}
    </dl>
  );
};

export default FilterList;
